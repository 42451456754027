import { debounce, get } from 'lodash-es';
import qs from 'qs';

import { isIframed, parseURL } from 'app/assets/js/util';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import { renderController } from 'BootQuery/Assets/js/BootQuery';
import * as LocalStorage from 'BootQuery/Assets/js/localStorage';
import Module from 'BootQuery/Assets/js/module';
import tr from 'BootQuery/Assets/js/translate';

import { PermissionSettings } from '../components/PermissionSettings';
import { ProvidedReports } from '../components/Reports';
import { userStore } from '../components/user-store';
import { UserMenu } from '../components/UserMenu';
import { ReactRoutes } from './react-routes';

function onSettingsChanged(changed) {
  if (!changed.User) {
    return;
  }

  if (changed.User.locale) {
    const { locale } = changed.User;
    const lang = locale.split('_')[0];

    window.target = 'body';
    window.targetElement = 'body';
    renderController(
      'get',
      window.Bootstrap.bootquery.controller,
      window.Bootstrap.bootquery.method,
      {},
      'Settings'
    );

    document.dispatchEvent(
      new CustomEvent('clickvoxLocaleChange', { detail: { lang, locale } })
    );
  }
}

function replaceUriParams(url, paramsObj) {
  const parsed = parseURL(url);

  const query = { ...parsed.paramsObj, ...paramsObj };

  const queryStr = qs.stringify(query);
  return queryStr ? `${parsed.pathname}?${queryStr}` : parsed.pathname;
}

export default class User extends Module {
  get provides() {
    return {
      reactWidgets: {
        userMenu: {
          component: UserMenu,
          target: '#user-menu-widget',
        },
        userPermissionSettings: {
          target: '#user-permissions-react-root',
          component: PermissionSettings,
        },
      },
      reports: ProvidedReports,
      reactRoutes: {
        ReactRoutes,
      },
    };
  }

  static matchReactRoute = '/user/reports/';

  init(data) {
    super.init();

    userStore.getState().init();

    $(document).ev('userSettingsChanged.user', (_e, changed) => {
      onSettingsChanged(changed);
    });

    // Try to remember last selected extension
    const extensionID = get(data, 'bootquery.session.extensionID');
    if (extensionID !== undefined) {
      LocalStorage.set('lastExtension', {
        extensionID: extensionID === 'null' ? null : parseInt(extensionID, 10),
        extension: data.bootquery.session.extension,
      });
    }
  }

  activateElements(target, data) {
    this.activateLoginPage(target, data);

    const userUserSettings = target.findElement(
      '#userSettings-form #setting-user'
    );
    if (userUserSettings.length) {
      const passwordElNames = [
        'previousPassword',
        'newPassword',
        'newPasswordConfirm',
      ];
      const passwordFieldsSelector = passwordElNames
        .map((name) => `input[name="userSettings[${name}]"]`)
        .join(', ');
      const passwordFields = userUserSettings.find(passwordFieldsSelector);
      userUserSettings
        .find('#password-change-collapse')
        .ev('show.bs.collapse', (e) => {
          passwordFields.prop('disabled', false);
          $(e.currentTarget)
            .closest('form')
            .findElement('[name="userSettings[passwordChanged]"]')
            .val('true');
        });

      userUserSettings
        .find('#password-change-collapse')
        .ev('hidden.bs.collapse', (e) => {
          passwordFields.prop('disabled', true);
          $(e.currentTarget)
            .closest('form')
            .findElement('[name="userSettings[passwordChanged]"]')
            .val('false');
        });
    }

    target
      .findElement('.dropdown-item[data-toggle="collapse"]')
      .ev('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        $($(e.currentTarget).attr('href')).collapse('toggle');
      });

    target.findElement('a[href="/user/logout"]').ev('click.user', (ev) => {
      ev.stopPropagation();
    });
  }

  activateLoginPage(target, data) {
    if (isIframed()) {
      target
        .findElement('.auth-provider-link')
        .attr('target', '_blank')
        .on('click.user', (ev) => {
          ev.preventDefault();
          const uri = replaceUriParams(ev.currentTarget.href, { framed: true });
          const authFrame = window.open(uri, '_blank', 'width=500, height=600');
          window.addEventListener('storage', (ev) => {
            if (ev.key === 'login' && ev.newValue === 'true') {
              authFrame.close();
              window.location.reload();
            }
          });
        });
    }

    const updateLoginMethod = this.updateLoginMethod.bind(this);
    target
      .findElement('#login-form input[name="username"]')
      .ev('keydown.user', debounce(updateLoginMethod, 400))
      .ev('blur.user', updateLoginMethod);
    this.updateLoginMethod(null);
  }

  async updateLoginMethod(event = null) {
    const trigger = event ? event.type : null;

    const authProviders = get(window.Bootstrap, 'bootquery.authProviders');
    if ((authProviders || []).length === 0) {
      return;
    }

    const username = $('#login-form input[name=username]').val();
    const prevUsername = $('#login-form').data('username');

    if (username === prevUsername) {
      return;
    }
    $('#login-form').data('username', username);

    let providerName = null;
    let provider = null;
    if (username.length > 0) {
      providerName = await Api.get(
        `/api/identity/authProviderForUsername/${username}`
      );
      provider = authProviders.find(({ name }) => name === providerName);
    }

    const prevProvider = $('#login-form').data('authProvider') || null;

    if (!provider && !prevProvider) {
      return;
    }

    if (provider && providerName !== prevProvider) {
      $('#login-form')
        .data('authProvider', providerName)
        .attr('action', `/identity/login/${providerName}`)
        .attr('method', 'GET');
      const actionText = tr('button.login_with', 'User', {
        provider: provider.displayName,
      });
      $('#login-form .normal-login-only')
        .addClass('d-none')
        .find('button, input')
        .prop('disabled', true);
      $('#login-form-submit').text(actionText);
      if (trigger === 'blur') {
        $('#login-form-submit').trigger('focus');
      }
    } else if (prevProvider) {
      $('#login-form')
        .data('authProvider', null)
        .attr('action', '/user/login')
        .attr('method', 'POST');
      $('#login-form-submit').text(tr('User:button.login'));
      $('#login-form .normal-login-only')
        .removeClass('d-none')
        .find('button, input')
        .prop('disabled', false);
      if (trigger === 'blur') {
        $('#login-form input[name=password]').trigger('focus');
      }
    }
  }
}
